
body{
    margin: 0;
}

img{
    max-width: 100%;
}
.box{
    width: 160px;
    height: 260px;
    line-height: 260px;
    text-align: center;
    display: inline-block;
    border: 1px dotted black;
    cursor: pointer;

    &+.box{
        margin-left: 20px;
    }
}

.prices{
    .price{
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    display: inline-block;
    border: 1px dotted black;
    cursor: pointer;
        &+.price{
            margin-left: 20px;
        }
        &.active{
            border: 2px solid green;
        }
    }
}